<template>
  <div class="mt-5">
    <v-card max-width="700" class="mx-auto">
      <v-row align="center" class="mx-auto" tile>
        <v-col cols="12" sm="12">
          <v-form v-model="valid" ref="form" @submit.prevent="submit">
            <!-- <v-form v-model="valid" ref="form" @submit.prevent="submit"> -->

            <div>
              <!-- <div v-if="!submitted"> -->
              <v-row align="center" class="list px-3 mx-auto">
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="title"
                    name="title"
                    label="제목"
                    auto-grow
                    background-color="white lighten-2"
                    required
                    :rules="nameRules"
                    :counter="100"
                    maxlength="100"
                    ref="inTitle"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="list px-3 mx-auto">
                <v-col cols="12" sm="12">
                  <!-- <v-text-field
                              height="200"
                              v-model="description"
                              name="description"
                              label="내용"
                              auto-grow
                              background-color="white lighten-2"
                              required
                              :rules="descriptionRules"
                              :counter="100"
                              max="100"
                              ref="inDescription"
                              ></v-text-field> -->
                  <v-textarea
                    auto-grow
                    v-model="description"
                    name="description"
                    label="내용"
                    background-color="white lighten-2"
                    required
                    :rules="descriptionRules"
                    :counter="1000"
                    max="1000"
                    ref="inDescription"
                  ></v-textarea>
                </v-col>
              </v-row>

              <p></p>
              <!-- _____________________파일첨부_____________________________ -->
              <!-- <div v-if="this.detailAttchfile===ture" id="hide"> -->
              <div>
                <!-- <div v-if="image" id="hide"> -->
                <v-row align="center" class="list px-3 mx-auto">
                  <v-col cols="12" sm="12">
                    <div class="alert alert-danger" role="alert">
                      *파일은 2M까지 가능합니다.
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div>
                      <div v-if="currentFile" class="progress">
                        <div
                          class="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          :aria-valuenow="progress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{ width: progress + '%' }"
                        >
                          {{ progress }}%
                        </div>
                      </div>

                      <label class="btn btn-default">
                        <!-- accept="image/jpeg, image/png" -->
                        <!-- @change="selectFile($event.target.name,$event.target.files)" -->
                        <!-- v-el="image"  -->
                        <!-- multiple -->
                        <input
                          type="file"
                          required
                          ref="upload"
                          accept="image/*"
                          @change="selectFile"
                        />
                      </label>

                      <div class="alert alert-light" role="alert">
                        {{ message }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- <div align="center"> -->
              <!-- <div v-else align="center"> -->
              <div v-if="image" id="show" align="center">
                <img width="50%" height="50%" :src="image" ref="previewsImg" />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button @click="removeImage">&nbsp;&nbsp;&nbsp;삭제</button>
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
              </div>
              <p></p>
              <v-divider></v-divider>
              <p></p>

              <!-- _____________________파일첨부_____________________________ -->

              <!-- _____________________detail파일첨부_____________________________ -->
              <!-- <div v-else id="show">
                              <v-row align="center" class="list px-3 mx-auto">
                                  <v-col 
                                    v-for="(item) in resFiles"
                                    :key="item.id"
                                    cols="12"
                                    sm="12"
                                    class="mt-5"
                                  >
                                        <div>
                                            <input 
                                              v-model="item.name"
                                              type="text"
                                              class="form-control"
                                              name="fileName"
                                            />
                                        </div>
                                  </v-col>
                              </v-row>
                            </div> -->

              <v-row class="list px-3 mx-auto">
                <v-col cols="12" sm="12" align="center">
                  <button v-if="currentUser" class="btn btn-success">
                    {{ button.text }}
                  </button>
                  <!-- <button type="submit" class="btn btn-primary" v-attr="disabled:blankInput">Crear</button> -->
                  <!-- <button :disabled="!selectedFiles" @click="saveBbs" class="btn btn-success">등록</button> -->
                </v-col>
              </v-row>
            </div>
            <!-- <div v-else>
                    <h4>You submitted successfully!</h4>
                    <button class="btn btn-success" @click="newBbs">Add</button>
                  </div> -->
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <!-- <el-button @click="post_visible = true">POST</el-button>
      <el-dialog :visible.sync="post_visible" title="Adding a new value!"> 
                    <span>Image_1</span>
                    <input type="file" name="file1" />
                    <br>
                    <br>

                    <span>Image_2</span>
                    <input type="file" name="file2" />
                    <br>
                    <br>

                    <span>Image_3</span>
                    <input type="file" name="file3" />
                    <br>
                    <br>

                    <el-button @click = "submit"> Submit </el-button>

      </el-dialog> -->
  </div>
</template>

<script>
import bbsDataService from "../services/BbsDataService";

export default {
  name: "add-bbs",
  // id:'#el',
  data() {
    return {
      valid: false,
      nameRules: [
        v => !!v || "제목을 입력하세요.",
        v => v.length <= 100 || "100자 까지 가능합니다."
      ],
      descriptionRules: [
        v => !!v || "내용을 입력하세요.",
        v => v.length <= 1000 || "1000자 까지 가능합니다."
      ],
      id: null,
      title: "",
      description: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      fileInfos: [],
      // published: false
      submitted: false,
      button: {
        text: undefined
      },
      seen: true,
      image: "",
      isImage: false,
      saveBbs: "",

      // using detail page
      modifyFlag: false,
      bbsId: this.$route.params.id,
      resFiles: [],
      fileUrl: "",
      fileName: "",
      detailAttchfile: false,
      newParam: this.$route.params.new,
      props: {
        model: {
          validator: function(val) {
            return val === null || typeof val === "string";
          }
        }
      },
      currPage: this.$route.params.currPage
    };
  },
  computed: {
    currentUser() {
      // console.log("computed-currentUser");
      return this.$store.state.auth.user;
    }
    //   blankInput:function(){
    //        if (!this.mensaje && !this.$$.image.files)
    //              return true;
    //        return false;
    //     }
  },
  methods: {
    getRequestParams(titile, description, file, bbsid, isImage) {
      let params = {};
      // params["title"] = '';
      // params["description"] ='';
      if (titile) {
        params["title"] = titile;
      }
      if (description) {
        params["description"] = description;
      }
      if (file) {
        params["file"] = file;
      }
      if (bbsid) {
        params["bbsId"] = bbsid;
      }
      if (isImage) {
        params["isImage"] = isImage;
      }
      return params;
    },
    async submit() {
      // console.log(this.$refs.form.validate());
      // console.log("##" + JSON.stringify(this.$refs.upload.children)); return;
      if (!this.$refs.form.validate()) {
        return;
      }
      // this.$refs.form.$el.submit()
      // this.$refs.submit.click();

      // await pause(1500)

      //create
      if (!this.bbsId && this.modifyFlag === false) {
        //  if(!this.selectedFiles){
        //    this.message = "파일을 선택 하세요.";
        //           return;
        //  }

        if (this.selectedFiles) {
          this.progress = 0;
          this.currentFile = this.selectedFiles.item(0);
        }

        //  console.log('create this.currentFile>',this.currentFile)

        let params = this.getRequestParams(
          this.title,
          this.description,
          this.currentFile,
          undefined
        );

        bbsDataService
          .create(params, event => {
            return (this.progress = Math.round(
              (100 * event.loaded) / event.total
            ));
          })
          .then(response => {
            // console.log('###bbsDataService.create>',response);
            // this.saveBbs.id = response.data.bbsSaveData.id;
            this.submitted = true;
            this.message = response.data.message.message;
            // return  this.$router.push('/bbslist');

            return this.$router.push("/bbslist/1");
            //  return  this.$router.push({
            //       name:'bbslist',
            //       params:{
            //         propcurrPage: this.currPage,
            //       }
            //    });
          })
          .catch(e => {
            this.progress = 0;
            this.message = "등록에 실패 하였습니다.";
            this.currentFile = undefined;
            console.log(e);
          });
        this.selectedFiles = undefined;

        //update
      } else if (this.bbsId && this.modifyFlag === true) {
        // if(!this.image){
        //   if(
        //       !this.selectedFiles
        //       || !this.currentFile
        //       || this.currentFile===''
        //     ){
        //       this.message = "파일을 선택 하세요.";
        //       return;
        //     }
        //     this.progress = 0;
        //     this.currentFile = this.selectedFiles.item(0);
        //   } else

        // if(this.image && this.selectedFiles){
        if (this.selectedFiles) {
          this.progress = 0;
          this.currentFile = this.selectedFiles.item(0);
        }

        // console.log('update this.>',this.selectedFiles);
        // console.log('update this.currentFile>',this.currentFile);
        // console.log('update json springify this.currentFile>',JSON.stringify(this.currentFile))
        // return;

        let params = this.getRequestParams(
          this.title,
          this.description,
          this.currentFile,
          this.bbsId,
          this.isImage
        );

        // console.log(JSON.stringify(params)); return;
        bbsDataService
          .update(params)
          .then(response => {
            // console.log('###bbsDataService.update>',response);
            // this.saveBbs.id = response.data.bbsSaveData.id;
            // this.submitted = true;
            this.message = response.data.message.message;
            // return  this.$router.push('/bbslist');

            return this.$router.push({
              name: "bbslist",
              params: {
                propcurrPage: this.currPage
              }
            });
          })
          .catch(e => {
            this.progress = 0;
            this.message = "등록에 실패 하였습니다.";
            this.currentFile = undefined;
            console.log(e);
          });

        // this.selectedFiles = undefined;
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    // initiate() { this.$refs.image.children[1].value = "" },
    setImage: function(output) {
      console.log("setimage");
      // console.log("##" + this.$refs.file.$el.children);
      (this.hasImage = true),
        // this.selectedFiles = this.$refs.file.$el.children,
        console.log(output);
    },
    //  selectFile(fieldName, fileList) {
    selectFile(e) {
      this.selectedFiles = e.target.files;
      this.message = "";

      //  console.log ('selectFile>click>',JSON.stringify(this.selectedFiles));
      //     if (!fileList.length) return;
      //     this.selectedFiles=fileList[0];

      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
        this.isImage = true;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(e) {
      this.image = "";
      this.isImage = false;
      this.$refs.upload.value = "";
    },
    readURL() {
      this.selectedFiles = this.$refs.upload.files;
    },

    newBbs() {
      this.submitted = false;
      this.tutorial = {};
    },
    getDisplayFiles(item) {
      return {
        id: item.id,
        fileUrl: item.fileUrl,
        name: item.name
      };
    }
  },
  mounted() {
    // console.log('mounted');
    // console.log('newparan>',this.newParam);
    // console.log('bbsAdd.route.currPage>',this.$route.params.currPage);

    if (this.$route.params.id) {
      bbsDataService.get(this.$route.params.id).then(
        response => {
          const { fileDB, description, message } = response.data.bbsDetailData;
          this.resFiles = fileDB.map(this.getDisplayFiles);
          this.bbsId = response.data.bbsDetailData.id;
          this.title = response.data.bbsDetailData.title;
          this.description = response.data.bbsDetailData.description;
          // if(this.description){
          //   this.description = this.description.replace(/(?:\r\n|\r|\n)/g, '<br />');
          // }
          this.detailAttchfile = true;
          this.seen = false;

          if (this.resFiles.length > 0) {
            //  this.$refs.file.files[0].value = this.resFiles[0].name;
            //  this.$refs.upload.files = this.resFiles;
            // this.selectFile(this.resFiles[0]);
            // $('input[type="file"]').change(this.resFiles.bind(this));
            // !this.$$.image.files.length ==0
            // $('input[type="file"]').change(this.resFiles[0]);

            this.isImage = true;
            this.image = this.resFiles[0].fileUrl;
            // this.$refs.upload.files[0].value = this.resFiles[0].name;
          }

          this.button.text = this.seen ? "등록" : "저장";
          this.modifyFlag = true;
        },
        error => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
    this.button.text = this.seen ? "등록" : "저장";

    if (this.newParam === "new") {
      this.removeImage();
      this.title = "";
      this.description = "";
      this.$refs.upload.value = null;
    }
  },
  watch: {
    watched() {
      console.log("watch");
    },
    model: function(val, oldVal) {
      // NOTE that we use a codeChanged state variable to avoid the unnecessary
      // change of the summernote code
      if (!this.codeChanged) {
        $(this.$el).code(val);
      } else {
        this.codeChanged = false;
      }
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

/* #visa {
  margin: 20px auto;
  max-width: 700px;
}
label{
  display: block;
  margin: 20px 0 10px;
}
input {
  font-size: 30px;
  border: 1px double rgb(102, 97, 96) ;
  border-radius: 4px;
} */
</style>
